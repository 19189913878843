import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    component:()=>import('@/views/home.vue'),
  },
  {
    path: '/homezc',
    name: 'homezc',
    component:()=>import('@/views/homezc.vue'),
  },
  {
    path: '/homegg',
    name: 'homegg',
    component:()=>import('@/views/homegg.vue'),
  },
  {
    path: '/noticeDetail',
    name: 'noticeDetail',
    component:()=>import('@/views/noticeDetail.vue'),
  },
  {
    path: '/homesq',
    name: 'homesq',
    component:()=>import('@/views/homesq.vue'),
  },
 
]

const router = createRouter({
  mode:'hahs',
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
