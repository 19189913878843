import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'


// 引入公共css
import "./assets/css/base.css"
import "./assets/css/common.css"
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
// import './assets/css/global.css';
import vant  from 'vant';
import 'vant/lib/index.css';
const app = createApp(App)
app.use(store).use(ElementPlus).use(i18n).use(router).use(vant).mount('#app')
