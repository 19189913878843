// ja.js
export default {
    message: {
      hello: 'こんにちは',
      title: '小麦粉の購入',
      currentPrice: '現在の価格:',
      amountReceived: '受け取った金額:',
      pay: '支払う',
      participate: '参加する',
      enterAmount: '金額を入力してください',
      invest: '投資する',
      flourNeeded: '必要な小麦粉:',
      usdtNeeded: '必要なUSDT:',
      profit: '利益:',
      confirm: '確認',
      claimableAssets: '私の収益です',
      todayEarnings: '今日の収益',
      totalEarnings: '総収益',
      claim: '請求する',
      directAddresses: '直接アドレス',
      number: '番号',
      address: 'アドレス',
      copy: 'コピー',
      bannerInfo: '金融の知恵',
      shareLink: "リンクを共有する",
      tips: '分散化、公平、公正、開放性と透明性',
      contractMessage: 'Pizzaswap契約の権限が破棄されました！',
      shareDetail: 'ピザエコシステムが未来を牽引し、ブロックチェーン技術によって強化され、デジタル経済における新しい信頼のエコシステムを構築します。透明で安全で効率的であり、新しいブロックチェーン時代をリードします！',
      noOpen:"乞うご期待です!",
      success:"操作成功です！",
      failed:"操作に失敗します！",
      yqcode:"招待コードの入力をお願いします",
      notice:"スマート・フォーチュンは未来の金融の新しい章を導きます",
      noticeinfo:"テクノロジーが飛躍的に進化する時代には、人工知能やビッグデータ、ブロックチェーンなどの先進技術が金融に浸透し、資産運用に大きな変化をもたらしています。スマートマネーは、新しい時代の金融発展のキーワードとなりつつあります。今日はスマート・フォーチュンの魅力についてお話しします"
    
      // その他の翻訳項目...
    },
    header:{
      userName: 'ユーザー名',
      languageToggle: '日本語',
      home: 'ホーム',
      assets: '資産',
      community: 'コミュニティ',
      announcements: 'お知らせ',
      pizzSwap: 'PizzSwap',
      bindReferrer: '推薦者をバインド',
      enterQuantity: '数量を入力してください',
      enterDddress: 'アドレスを入力してください',
      confirm: '確認'
    },
    assets:{
      pointA: 'Aポイント',
      pointB: 'Bポイント',
      pizza: 'ピザ',
      hbtc: 'HBTC',
      transfer: '振替',
      exchange: '交換',
      enterQuantity: '数量を入力してください',
      enterQuantityh: 'HBTCを入力してください',
      confirm: '確認',
      cancel: 'キャンセル',
      quantity: '利用可能な数量',
      bannerInfo: '資産の成長',
      tips: '科学はあなたの資産を健康的に成長させます',
      assets: '資産',
      address: "アドレス",
      quantity1:"数量",
      quantity2:"数量",
      enterDddress: 'アドレスを入力してください',
      exchange1: '交換する',
      obtain: '取得する',
      consumingF: '小麦粉の消費',
      consumingY: '酵母の消費',
      synthetic: '合成ピザ',
      exchangeHbt: 'HBTCと交換する',
    },
    notice:{
      bannerInfo: '最新ニュース',
      tips: '権威のある発表により、情報が適時に配信されます',
      detail: 'お知らせの詳細',
    },
    community:{
      my: "私の",
      shareLink: "リンクを共有する",
      Share: "私の共有",
      id: "ID",
      time:"バインディング時間",
      quantity:"購入数量",
      rewards:"報酬を得る",
      inviter:"招待者",
      address: "アドレス",
      personalInvestment: "個人投資",
      communityInvestment: "コミュニティ投資",
      copy: "コピー",
      bannerInfo: '社会的スタイルと機能',
      tips: '隣人が協力して温かいコミュニティ文化サークルを構築します',
    }
    // その他のモジュールの翻訳項目...
  }
  