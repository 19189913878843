// en.js
export default {
  message: {
    hello: 'Hello',
    title: 'Purchase Flour',
    currentPrice: 'Current Price:',
    amountReceived: 'Amount Received:',
    pay: 'Pay',
    participate: 'Participate',
    enterAmount: 'Please enter the amount',
    invest: 'Invest',
    flourNeeded: 'Flour Needed:',
    usdtNeeded: 'USDT Needed:',
    profit: 'Profit:',
    confirm: 'Confirm',
    claimableAssets: 'My earnings',
    todayEarnings: 'Today\'s Earnings',
    totalEarnings: 'Total Earnings',
    claim: 'Claim',
    directAddresses: 'Direct Addresses',
    number: 'Number',
    address: 'Address',
    copy: 'Copy',
    bannerInfo: 'Financial Wisdom',
    shareLink: "Share Link",
    tips:'Decentralization, fairness, justice, openness and transparency',
    contractMessage: 'Pizzaswap contract permissions have been discarded!',
    shareDetail: 'Pizza ecosystem drives the future, empowered by blockchain technology, building a new ecosystem of trust in the digital economy, transparent, secure, and efficient, leading the new era of blockchain!',
    noOpen:"Stay tuned!",
    success:"Operation is successful!",
    failed:"Operation failed!",
    yqcode:"Please enter the invitation code",
    notice:"Intelligent wealth, leading the future of financial new chapter",
    noticeinfo:"In the era of rapid development of science and technology, advanced technologies such as artificial intelligence, big data, and blockchain have gradually penetrated into the financial field, bringing unprecedented changes to wealth management. Intelligent wealth is becoming the key word of financial development in the new era. Today, let's explore the charm of smart wealth."
    // 其他翻译项...
  },
  header:{
    userName: 'Username',
    languageToggle: '中文',
    home: 'Home',
    assets: 'Assets',
    community: 'Community',
    announcements: 'Announcements',
    PizzaSwap: 'PizzaSwap',
    bindReferrer: 'Bind Referrer',
    enterQuantity: 'Please enter quantity',
    enterDddress: 'Please enter the address',
    confirm: 'Confirm'
  },
  assets:{
    pointA: 'a Points',
    pointB: 'b Points',
    pizza: 'Pizza',
    hbtc: 'Hbtc',
    transfer: 'Transfer',
    exchange: 'Exchange',
    enterQuantity: 'Please enter quantity',
    enterQuantityh: 'Please enter Hbtc',
    enterQuantityp:"Please enter the Pizza redemption quantity",
    confirm: 'Confirm',
    cancel: 'Cancel',
    quantity: 'Available',
    bannerInfo: 'Wealth growth',
    tips: 'Science helps you grow your wealth healthily',
    assets: 'assets',
    address: "Address",
    quantity1:"Quantity",
    quantity2:"Quantity",
    enterDddress: 'Please enter the address',
    exchange1: 'exchange',
    obtain: 'obtain',
    consumingF: 'Flour',
    consumingY: 'Yeast',
    synthetic: 'Synthetic Pizza',
    exchangeHbt: 'Exchange Hbt',
  },
  notice:{
    bannerInfo: 'Latest news',
    tips: 'Authoritative announcement ensures that information is delivered in place',
    detail: 'Announcement',
  },
  community:{
    my: "my",
    shareLink: "Share Link",
    Share: "My Share",
    id: "ID",
    time:"Number",
    quantity:"Purchase quantity",
    rewards:"Get Rewards",
    inviter:"Inviter",
    address: "Address",
    personalInvestment: "Personal",
    communityInvestment: "Community",
    copy: "Copy",
    bannerInfo: 'social style and features',
    tips: 'Neighbors work together to build a warm community cultural circle',
  }
  // 其他模块的翻译项...
}
