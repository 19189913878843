// ko.js
export default {
    message: {
      hello: '안녕하세요',
      title: '밀가루 구매',
      currentPrice: '현재 가격:',
      amountReceived: '받은 금액:',
      pay: '지불하기',
      participate: '참여하기',
      enterAmount: '금액을 입력하세요',
      invest: '투자하기',
      flourNeeded: '필요한 밀가루:',
      usdtNeeded: '필요한 USDT:',
      profit: '수익:',
      confirm: '확인',
      claimableAssets: '제 수입은',
      todayEarnings: '오늘의 수익',
      totalEarnings: '총 수익',
      claim: '청구하기',
      directAddresses: '직접 주소',
      number: '번호',
      address: '주소',
      copy: '복사하기',
      bannerInfo: '금융 지혜',
      shareLink: "링크 공유하기",
      tips: '탈중앙화, 공정성, 정의, 개방성과 투명성',
      contractMessage: 'Pizzaswap 계약 권한이 폐기되었습니다!',
      shareDetail: '피자 생태계가 미래를 이끌고, 블록체인 기술로 지원되며, 디지털 경제의 새로운 신뢰 생태계를 구축합니다. 투명하고, 안전하며, 효율적입니다. 새로운 블록체인 시대를 이끕니다!',
      noOpen:"기대해주세요!",
    success:"작업이 성공했습니다!",
    failed:"작업이 실패했습니다!",
    yqcode:"초대번호를 입력해주세요",
    notice:"지능재부는 미래 금융의 새로운 장을 선도한다",
    noticeinfo:"과학기술이 급속하게 발전하는 시대에 인공지능, 빅데이터, 블록 체인 등 선진기술이 점차 금융 영역에 스며들어 자산관리 분야에 미증유의 변혁을 가져온다.지능재부는 새로운 시대 금융발전의 핵심단어로 되고있다.오늘은 스마트 부의 매력을 알아봅시다."
    
      // 기타 번역 항목...
    },
    header:{
      userName: '사용자 이름',
      languageToggle: '한국어',
      home: '홈',
      assets: '자산',
      community: '커뮤니티',
      announcements: '공지사항',
      pizzSwap: 'PizzSwap',
      bindReferrer: '추천인 바인드',
      enterQuantity: '수량을 입력하세요',
      enterDddress: '주소를 입력하세요',
      confirm: '확인'
    },
    assets:{
      pointA: 'A 포인트',
      pointB: 'B 포인트',
      pizza: '피자',
      hbtc: 'HBTC',
      transfer: '이체',
      exchange: '교환',
      enterQuantity: '수량을 입력하세요',
      enterQuantityh: 'HBTC를 입력하세요',
      confirm: '확인',
      cancel: '취소',
      quantity: '가용 수량',
      bannerInfo: '자산 성장',
      tips: '과학이 자산을 건강하게 성장시킵니다',
      assets: '자산',
      address: "주소",
      quantity1:"수량",
      quantity2:"수량",
      enterDddress: '주소를 입력하세요',
      exchange1: '교환하기',
      obtain: '얻다',
      consumingF: '밀가루 소비',
      consumingY: '효모 소비',
      synthetic: '합성 피자',
      exchangeHbt: 'HBTC 교환하기',
    },
    notice:{
      bannerInfo: '최신 뉴스',
      tips: '권위 있는 발표는 정보가 제 시간에 전달되도록 합니다',
      detail: '공지사항 세부 정보',
    },
    community:{
      my: "나의",
      shareLink: "링크 공유하기",
      Share: "나의 공유",
      id: "ID",
      time:"바인딩 시간",
      quantity:"구매 수량",
      rewards:"보상 받기",
      inviter:"초대자",
      address: "주소",
      personalInvestment: "개인 투자",
      communityInvestment: "커뮤니티 투자",
      copy: "복사하기",
      bannerInfo: '사회적 스타일과 기능',
      tips: '이웃들이 함께 따뜻한 커뮤니티 문화를 만들어갑니다',
    }
    // 기타 모듈의 번역 항목...
  }
  